<template>
    <tour :steps="tourSteps">
        <div class="content-wrapper" id="empresa">
            <PageHeader screenName="Ver empresas" :linkItems="linkItems"/>
        
            <main class="card hidden_overflow_tabela">
                <div v-if="canInsert || insertPermissionNational" class="py-2 px-2">
                    <b-button @click="redirectToRegister()" variant="primary-button">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"  
                        />
                        <span class="align-middle">Adicionar nova empresa</span>
                    </b-button>
                </div>
                <filters
                        @actionSearch="handleSubmitFormFilters"
                        @actionClear="clearFilters"
                >
                
                <b-row class="pt-1 px-2">
                    <b-col lg="4" md="6" sm="7">
                            <b-form-group 
                            label="CPF ou CNPJ"
                            label-for="cnpj-ou-cpf-input"
                            label-class="font_size_label_lista_empresa">
                                <b-form-input
                                    id="cnpj-ou-cpf-input"
                                    placeholder="00.000.000/0000-00"
                                    v-model="search.documento"
                                    @input="validaMascara"
                                    v-mask="!cnpj ? '###.###.###-##' : '##.###.###/####-##'"
                                />
                                <small v-if="documentoError" class="text-danger">
                                    {{ documentoError }}
                                </small>
                            </b-form-group>
                    </b-col>
                    <b-col lg="4" md="6" sm="7">
                        <company-input-filter
                            v-model="search.company_name"
                            :form="search"
                        />
                    </b-col>

                    <b-col lg="4" md="6" sm="7">
                            <b-form-group 
                                label="Cadastro"
                                label-for="cadastro-input"
                                label-class="font_size_label_lista_empresa"
                            >
                                <v-select
                                    id="cadastro-input"
                                    :options="cadastrosForSelect"
                                    v-model="search.cadastro"
                                    placeholder="Selecione o cadastro"
                                >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                            </b-form-group>
                        </b-col>

                        <b-col lg="4" md="6" sm="7">
                            <b-form-group 
                                label="UF"
                                label-for="uf-input"
                                label-class="font_size_label_lista_empresa"
                            >
                                <v-select
                                    id="uf-input"
                                    :options="ufsForSelect"
                                    v-model="search.uf"
                                    placeholder="Selecione uma UF"
                                    :clearable="false"
                                    :reduce="(option => option.uf)"
                                    @input="loadCidades"
                                    label="nome"
                                >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="6" sm="7">
                            <b-form-group label="Cidade" label-for="cidade" label-class="font_size_label">
                                <v-select
                                    id="cidade"
                                    :options="cityOptions"
                                    v-model="search.cidade"
                                    placeholder="Selecione uma cidade"
                                    label="nome"
                                    :clearable="true"
                                >
                                    <template #option="{ nome, uf }">
                                        <div class="d-center">
                                        {{ nome }} - {{ uf }}
                                        </div>
                                    </template> 
                                    <template #selected-option="{ nome, uf }">
                                        <div class="d-center">
                                        {{ nome }} - {{ uf }}
                                        </div>
                                    </template> 
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                </b-row>
                    </filters>
                <div v-if="loadingData" class="d-flex justify-content-center my-3">
                    <div class="spinner-border text-custom-blue" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <section class="filters pb-1 px-2" v-if="!loadingData">
                        <div class="d-flex align-items-center">
                            <span class="mr-1" for="tableSize">Mostrar</span>
                            <v-select
                                id="tableSize"
                                v-model="paginationData.defaultSize"
                                @input="updateTableSize($event)"
                                :options="table.optionsSize"
                                :clearable="false"
                                style="width: 100px;"
                            />
                        </div>
                    </section>
                <section class="list-empresa" v-if="!loadingData">
                    <b-table
                        id="tableEmpresa"
                        responsive
                        :stickyColumn="true"
                        :busy.sync="filtering"
                        :fields="table.fields"
                        :items="table.items"
                        @sort-changed="ordenarColuna"
                        no-local-sorting
                        :no-sort-reset="true"
                    >
                        <template #cell(documento)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                                    v-if="row.item.documento.length == 11"
                                    v-b-tooltip
                                    :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
                            {{ row.item.documento | VMask('###.###.###-##') }}
                            </span>
                                <span class="font-weight-bold comprimento_maximo_celulas"
                                    v-else
                                    v-b-tooltip
                                    :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
                            {{ row.item.documento | VMask('##.###.###/####-##') }}
                            </span>
                        </template>
                        <template #cell(nome_empresa)="row">
                            <div class="d-flex align-items-center">
                                <div class="d-flex">
                                    <img 
                                        v-b-tooltip
                                        title="Base Nacional"
                                        v-if="row.item.base_nacional" 
                                        :src="row.item.icone" 
                                        class="mr-15px" 
                                        height="20px" 
                                        alt="icone customizado"/>
                                </div>
                                <div>
                                    <span class="comprimento_maximo_celulas" :class="{ 'espacamento-empresa': !row.item.base_nacional }" v-b-tooltip
                                        :title="row.item.razao_social && row.item.razao_social.length > lengthColumnMaximo ? row.item.razao_social : null"
                                    >
                                        {{ row.item.razao_social ? row.item.razao_social : '-' }}
                                    </span>
                                    <br/>
                                    <span class="font-weight-bold comprimento_maximo_celulas" :class="{ 'espacamento-empresa': !row.item.base_nacional }" v-b-tooltip
                                        :title="row.item.nome_empresa.length > lengthColumnMaximo ? row.item.nome_empresa : null"
                                    >
                                        {{ row.item.nome_empresa }}
                                    </span>
                                </div>
                            </div>    
                        </template>                        
                        <template #cell(cidade)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            v-b-tooltip
                            :title="row.item.cidade && row.item.cidade.length > lengthColumnMaximo ? row.item.cidade : null">
                            {{ row.item.cidade ? row.item.cidade : '-' }}
                            </span>
                        </template>
                            <template #cell(uf)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            v-b-tooltip
                            :title="row.item.uf && row.item.uf.length > lengthColumnMaximo ? row.item.uf : null">
                            {{ row.item.uf ? row.item.uf : '-'}}
                            </span>
                        </template>
                            <template #cell(cadastro)="row">
                            <RowSituacaoCadastro
                                :length-column-maximo="lengthColumnMaximo"
                                :situacao-cadastro="row.item.cadastro"
                                classe="comprimento_maximo_celulas"
                            />
                        </template>
                        <template #cell(actions)="row">
                            <feather-icon
                                @click.prevent="redirectToShow(row.item)"
                                id="view-empresa"
                                role="button"
                                class="text-custom-blue mr-1"
                                icon="EyeIcon"
                                size="18"
                            />
                            <feather-icon
                                    v-if="canEdit || (editPermissionNational && row.item.base_nacional)"
                                @click.prevent="redirectToEdit(row.item)"
                                id="edit-empresa"
                                role="button"
                                class="text-custom-blue"
                                icon="EditIcon"
                                size="18"
                            />
                        </template>
                    </b-table>
                    <div class="pb-2 px-1">
                        <span v-if="withoudEmpresa" class="ml-1">Nenhuma empresa encontrada</span>
                        <b-col sm="12" class="mt-2">
                            <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                        </b-col>
                    </div>
                </section>
            </main>
        </div>
    </tour>
</template>
 
<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import RowSituacaoCadastro from '@/views/components/custom/empresa/RowSituacaoCadastro.vue'
import { getListFilter } from '@core/utils/store/getStore';
import { setListFilter } from '@core/utils/store/setStore';
import national from  '@/assets/custom-icons/pages/national.svg'
import Filters from '@/views/components/custom/filters/Filters.vue';
import Cidade from '../relatorio/parts/Cidade.vue';
import tourMixin from "@/mixins/tourMixin";
import listCompanyMixin from "./mixins/listCompanyMixin";
import CompanyInputFilter from '@/views/components/custom/empresa/CompanyInputFilter.vue';

export default {
    title: 'Empresas',
    mixins: [tourMixin, listCompanyMixin],
   components: {
     RowSituacaoCadastro,
     BForm, BCol, BRow, BFormGroup, BFormInput,
     BButton, BTable, vSelect,
     CustomPagination, PageHeader,
      Filters,
      Cidade,
      CompanyInputFilter
   },
 
   directives: {
       'b-tooltip': VBTooltip
   },
 
   data() {
       return {
            national,
           alert: {
               errorMessage: '',
               show: false
           },
           linkItems: [
               {
                   name: 'Empresas',
                   active: true
               }
           ],
           editPermissionNational: this.$can(actions.ATUALIZAR, subjects.TIPO_ATUACAO_EMPRESA_BASE_NACIONAL),
           insertPermissionNational: this.$can(actions.INSERIR, subjects.TIPO_ATUACAO_EMPRESA_BASE_NACIONAL),
           canEdit: false,
           canInsert: false,
           timeID: null,
           filtering: false,
           loadingData: false,
           withoudEmpresa: false,
           cnpj: false,
           search: {
               cidade: null,
               documento: null,
               company_name: null,
               cadastro: null,
               uf: null,
               company_name_filter: 'nome_fantasia'
           },
           table: {
               fields: [
                   {key: "documento", label: "CNPJ | CPF", sortable: true, class: 'comprimento_colunas'},
                   {key: "nome_empresa", label: "EMPRESA", sortable: true, class: 'comprimento_maximo_tabela_header'},
                   {key: "cidade", label: "CIDADE", sortable: true, class: 'comprimento_colunas'},
                   {key: "uf", label: "UF", sortable: true, class: 'comprimento_colunas'},
                   {key: "cadastro", label: "CADASTRO", sortable: true, class: 'comprimento_colunas'},
                   {key: "actions", label: "AÇÕES", sortable: false, class: 'comprimento_colunas col-1'}
               ],
               items: [],
               optionsSize: [10, 25, 50, 100],
               columnOrder: 'nome_empresa',
               order: 'asc',
               situacaoCadastro: true
           },
           paginationData: {
               currentPage: 1,
               totalLines: 0,
               fromLine: 0,
               toLine: 0,
               defaultSize: 10,
           },
           itemDesativar: null,
           modal: {
               showModal: false,
               typeModal: 'success',
           },
           lengthColumnMaximo: 28,
           documentoError: '',
           cadastrosForSelect: [
                "Todos",
                "Completo",
                "Parcial",
                "Incompleto"
           ],
           ufsForSelect: [
                { "uf": "Todos", "nome": "Todos" },
           ],
           cityOptions: [],
       }
   },

   mounted() {
       if(this.loadData){
           this.mountInit();
       }
   },
 
   methods: {
        mountInit(){
            if(this.verifyCanList()){
            this.loadDataEmpresa();
            }
            this.filtragemSalva();
            let self = this; 
            window.addEventListener('keydown', function(e) {
                if(e.key == 'Enter') {
                    self.loadDataEmpresa()
                }
            });
            this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
            this.canInsert = this.verifyPermissions(actions.INSERIR)

            this.loadFilters();
        },
        loadCidades(){
            const parameters = {
                todos: true,
                uf: this.search.uf == "Todos" ? null : this.search.uf,
            };

            this.$http
            .get(this.$api.cidade(), {params: parameters})
            .then(({ data }) => {
                this.cityOptions = data;
            });
        },
        setParams(){
            return {
                    nomeEmpresa: this.search.company_name,
                    company_name_filter: this.search.company_name_filter,
                    documento: this.search.documento,
                    tamanho: this.paginationData.defaultSize,
                    pagina: this.paginationData.currentPage,
                    colunaOrdenar: this.table.columnOrder,
                    ordem: this.table.order,
                    situacaoCadastro: this.table.situacaoCadastro,
                    situacao: this.search.cadastro != "Todos" ? this.search.cadastro : null,
                    uf: this.search.uf == "Todos" ? null : this.search.uf,
                    id_cidade: this.search.cidade ? this.search.cidade.id_cidade : null,
                    todos: true,
                    departamento: this.$store.state.sessions.userData.departamento.uf,
            }
        },
        loadDataEmpresa(first = false) {
            this.documentoError = ''
            let params = this.setParams();
            this.$http.get(this.$api.saveAndGetEmpresa(), {
                params: params
            }).then(({ data }) => {
                data.data.map(item => {
                    if(item.base_nacional){
                        item.icone = this.national
                    }
                });

                this.table.items = data.data

                if(!first){
                    params.route = this.$router.currentRoute.name
                    setListFilter(params);
                }

                this.withoudEmpresa = data.data.length == 0;

                this.filtering = false;

                this.startPagination(data)

            }).catch(({ response: { data } }) => {
                    this.documentoError = data.error;
                    this.filtering = false;
                })
        },

        filtragemSalva(){
            const savedFilter = getListFilter() ? getListFilter() : null;
            if(savedFilter && savedFilter.route == this.$router.currentRoute.name){
                this.filtering = true;
                this.search.company_name = savedFilter.company_name;
                this.search.company_name_filter = savedFilter.company_name_filter;
                this.search.documento = savedFilter.documento;
                this.paginationData.defaultSize = savedFilter.tamanho;
                this.table.situacaoCadastro = savedFilter.situacaoCadastro;
                this.search.cadastro = savedFilter.situacao;
                this.search.uf = savedFilter.uf;
                this.search.cidade = savedFilter.cidade;
                this.loadDataEmpresa(true);
            }
        },
 
        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },
    
        ordenarColuna(sort) {
            if(sort.sortBy == "cadastro"){
                this.table.situacaoCadastro = true;
            }else{
                this.table.situacaoCadastro = false;
            }

            const colunasFiltro = {
                cadastro: 'situacao_cadastro',
                uf: 'uf',
                cidade: 'cidade',
                nome_empresa: 'nome_empresa',
                documento: 'documento'
            };

            this.table.columnOrder = colunasFiltro[sort.sortBy];
            this.table.order = sort.sortDesc ? 'desc' : 'asc';
            this.filtering = true;
            this.loadDataEmpresa();
        },
    
        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.loadDataEmpresa();
        },
    
        updateTableSize(event) {
            if(!event) {
                this.paginationData.defaultSize = 10;
            }
            this.filtering = true;
    
            this.paginationData.currentPage = 1;
            this.loadDataEmpresa();
        },

        validaMascara() {
            const documento = this.search.documento.replace(/\D/g, "");

            if(documento.length <= 11){
                this.cnpj = false
            }else{
                this.cnpj = true
            }
        },
    
        redirectToEdit({id_empresa, ufs_enderecos_empresa}) {
            const ufsEnderecosEmpresa = ufs_enderecos_empresa != null 
                ? ufs_enderecos_empresa.replace('{', '').replace('}', '').split(',')
                : '';

            this.$router.push(
                {
                    name: 'empresa-edit',
                    params: {
                        idEmpresa: id_empresa,
                        ufs: ufsEnderecosEmpresa
                    }
                }
            );
        },
    
        redirectToShow({id_empresa}) {
            this.$router.push(
                {
                    name: 'empresa-show',
                    params: {
                            idEmpresa: id_empresa,
                    }
                }
            );
        },
    
        redirectToRegister() {
            this.$router.push({name: 'empresa-register'});
        },

        verifyPermissions(action) {
            const permission = this.$can(actions[action], subjects.EMPRESAS)
            return [permission].includes(true);
        },

        verifyCanList() {        
            const permissionLista = this.$can(actions[actions.VISUALIZAR], subjects.USUARIO_EMPRESA_LISTA_EMPRESA )
            return [permissionLista].includes(true);
        },
       
        async loadFilters(){
            await this.$http.get(this.$api.getEstados()).then(({ data }) => {
                data.forEach((estado) => {
                    this.ufsForSelect.push({
                        uf: estado.uf,
                        nome: estado.nome,
                    })
                })
            });

            this.loadCidades();
        },
        handleSubmitFormFilters() {
        this.paginationData.currentPage = 1
        this.loadDataEmpresa()
        },

        clearFilters() {
        this.search.documento = null
        this.search.company_name = null
        this.search.company_name_filter = 'nome_fantasia'
        this.search.cadastro = null
        this.search.uf = null
        this.search.cidade = null
        this.loadCidades();

        this.loadDataEmpresa()
        }, 
   }
}
</script>
 
<style>
    .font_size_label_lista_empresa{
        font-size: 15px;
        margin-top: -5px;
    }
    .comprimento_maximo_celulas{
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
    }
    .comprimento_maximo_tabela_header{
        width: 300px;
    }
    .hidden_overflow_tabela{
        overflow: hidden;
    }
    .comprimento_colunas{
        min-width: 150px;
        text-align: center;
    }
    .cor_botao{
      color: #2772C0 !important;
      border: 1px solid #2772C0 !important;
    }
    .espacamento-empresa{
        padding-left: 38px;
    }
</style>

